import { useTranslation } from "react-i18next";
import RVOPartnerUpWithUsTemplate from "../04-templates/RVOTargetGroupTemplate/RVOTargetGroupTemplate";

export const FuerSteuerberaterPage = (): JSX.Element => {
  const { t } = useTranslation();
  const pageInformation = {
    title: t("fuerSteuerberater.title"),
    pretitle: t("fuerSteuerberater.pretitle"),
    subtitle: t("fuerSteuerberater.subtitle"),
  };
  const benefitsSection = {
    pretitle: t("fuerSteuerberater.benefits.pretitle"),
    title: t("fuerSteuerberater.benefits.title"),
    benefits: [
      {
        title: t("fuerSteuerberater.benefits.benefitEfficency.title"),
        description: t(
          "fuerSteuerberater.benefits.benefitEfficency.description"
        ),
        illustration: "/app/illustrations/time-management.svg",
      },
      {
        title: t("fuerSteuerberater.benefits.benefitLoyalty.title"),
        description: t("fuerSteuerberater.benefits.benefitLoyalty.description"),
        illustration: "/app/illustrations/online-business-collaboration.svg",
      },
      {
        title: t("fuerSteuerberater.benefits.benefitRisk.title"),
        description: t("fuerSteuerberater.benefits.benefitRisk.description"),
        illustration: "/app/illustrations/first-aid-kit.svg",
      },
    ],
  };
  const stepsSection = {
    title: t("fuerSteuerberater.how.title"),
    illustration: "/app/illustrations/business-growth.svg",
    steps: [
      {
        title: t("fuerSteuerberater.how.step1.title"),
        description: t("fuerSteuerberater.how.step1.description"),
      },
      {
        title: t("fuerSteuerberater.how.step2.title"),
        description: t("fuerSteuerberater.how.step2.description"),
      },
      {
        title: t("fuerSteuerberater.how.step3.title"),
        description: t("fuerSteuerberater.how.step3.description"),
      },
    ],
  };
  return (
    <RVOPartnerUpWithUsTemplate
      pageInformation={pageInformation}
      benefitsSection={benefitsSection}
      stepsSection={stepsSection}
    />
  );
};

export default FuerSteuerberaterPage;
